<section>
  <div class="main">
    <div class="wrapper">
      <h3 data-aos="fade-right" data-aos-duration="1000">Skills</h3>
      <p class="skill-set-text">
        {{ "skillSetText" | translate }}
      </p>
      <div class="skill-set-container">
        <div class="skill-set">
          <div class="skills-frontend">
            <div class="skill-icons show">
              <div
                class="skill-icon"
                *ngFor="let skill of skillSetFrontend; let i = index"
                [ngClass]="{
                  'last-skill': i === skillSetFrontend.length - 1
                }"
              >
                <img
                  class="last-skill-img"
                  src="./assets/img/icons/skillset/frontend/{{ skill.icon }}"
                  alt=""
                />
                <p class="skill-icon-p">{{ skill.name }}</p>
                <div class="popup-window">
                  <p>{{ "eagerToLearn" | translate }}</p>
                  <div class="interest-icons">
                    <div class="interest">
                      <img
                        src="./assets/img/icons/skillset/interest/react.svg"
                        alt="React"
                      />
                      <p>React</p>
                    </div>
                    <div class="interest">
                      <img
                        src="./assets/img/icons/skillset/interest/vue.svg"
                        alt="Vue"
                      />
                      <p>Vue.js</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="skills-backend">
            <div class="skill-icons show">
              <div
                class="skill-icon"
                *ngFor="let skill of skillSetBackend; let i = index"
                [ngClass]="{
                  'last-skill': i === skillSetBackend.length - 1
                }"
              >
                <img
                  class="last-skill-img"
                  src="./assets/img/icons/skillset/backend/{{ skill.icon }}"
                  alt=""
                />
                <p
                  class="skill-icon-p"
                  [ngClass]="{
                    'adjust-font-height': i === skillSetBackend.length - 2
                  }"
                >
                  {{ skill.name }}
                </p>
                <div class="popup-window">
                  <p>{{ "eagerToLearn" | translate }}</p>
                  <div class="interest-icons">
                    <div class="interest">
                      <img
                        src="./assets/img/icons/skillset/interest/csharp.svg"
                        alt="CSharp"
                      />
                      <p>C#</p>
                    </div>
                    <div class="interest">
                      <img
                        src="./assets/img/icons/skillset/interest/netcore.svg"
                        alt=".NET Core"
                      />
                      <p>.NET Core</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="fill-button" (click)="toggleSkills('frontend')">
          Frontend
        </button>
        <button class="fill-button" (click)="toggleSkills('backend')">
          Backend
        </button>
      </div>
    </div>
  </div>
</section>
