<section>
  <div class="main">
    <div class="top-content">
      <a href="https://christian-grund.dev/"><p class="logo">&lt;/CG&gt;</p></a>
      <p>© Christian Grund 2024</p>
      <div class="social-icons">
        <a href="https://github.com/christian-grund/" target="_blank"
          ><img src="./assets/img/icons/social/github_blanc.svg" alt="Github" />
        </a>
        <a href="mailto:mail@christian-grund.dev">
          <img src="./assets/img/icons/social/mail_blanc.svg" alt="Mail" />
        </a>

        <a
          href="https://www.linkedin.com/in/christian-grund-developer/"
          target="_blank"
        >
          <img
            src="./assets/img/icons/social/linkedin_blanc.svg"
            alt="LinkedIn"
          />
        </a>
      </div>
    </div>

    <div class="imprint">
      <div class="textsites-wrapper">
        <a routerLink="imprint" (click)="scrollToTop()"
          ><p>{{ "imprint" | translate }}</p></a
        >
        <div
          class="imprint-underline"
          [ngClass]="{
            english: currentLanguage === 'en',
            german: currentLanguage === 'de'
          }"
        ></div>
      </div>
    </div>
  </div>
</section>
